import moment from 'moment';
import pick from 'lodash/pick';
import {MetaConstants, StartGuideBlockConstants} from '../helpers/Constants'

// Returns if a value is a string
export function isString(value) {
    return typeof value === 'string' || value instanceof String;
}

export function isObject (value) {
    return typeof value === 'object' || value instanceof Object;
}

export function campaignOverlayStatus(isEnableCampaignOverlay) {
    if(typeof window !== "undefined") {
        return isEnableCampaignOverlay ? window.sessionStorage.setItem('isEnableCampaignOverlay', true) : window.sessionStorage.removeItem('isEnableCampaignOverlay');
    }
}

export function calculateHeaderHeight() {
    // Not really clean, but we have to do this this way to change
    // the layout's padding due to the sticky navigation

    const header = document.querySelector('.zone-header');
    const height = header ? header.clientHeight : 0;
    const layout = document.querySelector('.layout:not(.ob-spotlight-layout):not(.scrolling-header)');
    if (!layout) {
        return;
    }
    layout.style.paddingTop = `${height}px`;
}

export function getProductVariantFields(productVariantBinOverlay) {
    let binFields = ["binName", "binValue", "binImage.fields.assetId", "classNames", "binImage.fields.asset"];
    let binData = productVariantBinOverlay && productVariantBinOverlay.map((item, index) => pick(item.fields, binFields));
    return window.btoa(unescape(encodeURIComponent(customJsonstringify(binData))));
}

export function getColorStyle(color) {
    if (color) {
        const colorCode = color?.fields?.colorCode;
        const colorCodeTop = color?.fields?.colorCodeTop || colorCode;
        const colorCodeBottom = color?.fields?.colorCodeBottom || colorCode;
        const gradientStyle = color?.fields?.gradientStyle;

        switch (gradientStyle) {
            case 'linear':
                return {
                    backgroundColor: colorCode,
                    backgroundImage: `linear-gradient(to bottom, ${colorCodeTop} 0%, ${colorCodeBottom} 100%)`,
                };
            case 'highlightTop':
                return {
                    backgroundColor: colorCode,
                    backgroundImage: `radial-gradient(circle at center top, ${colorCodeTop} 0%, ${colorCodeBottom} 100%)`,
                };
            case 'highlightBottom':
                return {
                    backgroundColor: colorCode,
                    backgroundImage: `radial-gradient(circle at center bottom, ${colorCodeBottom} 0%, ${colorCodeTop} 100%)`,
                };
            default:
                if (colorCode) {
                    return {
                        backgroundColor: colorCode
                    };
                } else {
                    return {};
                }
        }
    } else {
        return {};
    }
}

export function getRGBFromHexColor(color) {
    let red = 0;
    let green = 0;
    let blue = 0;
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    if (result) {
        red = parseInt(result[1], 16);
        green = parseInt(result[2], 16);
        blue = parseInt(result[3], 16);
    }

    return {red, green, blue};
}

// Cross browser compatible version of window.scrollTo(options)
export function smoothVerticalScroll(element, where, extraElemHeight = 0, time = 300) {
    // The extraElementHeight is the height of any element that isn't accounted by element.getBoundingClientRect().top
    const header = document.querySelector('.zone-header:not(.visible-false)');
    const headerHeight = header?.offsetHeight ? header.offsetHeight : 0;
    const elementTop = element.getBoundingClientRect().top;
    const elementAmount = (elementTop - headerHeight - extraElemHeight) / 100;
    let currentTime = 0;
    while (currentTime <= time) {
        window.setTimeout(smoothVerticalScrollBehaviour, currentTime, elementAmount, where);
        currentTime += time / 100;
    }
}

function smoothVerticalScrollBehaviour(elementAmount, where) {
    if (where === 'center' || where === '') {
        window.scrollBy(0, elementAmount / 2);
    }
    if (where === 'top') {
        window.scrollBy(0, elementAmount);
    }
}

export function focusOnElement(element) {
    if (!element) {
        return;
    }

    element.setAttribute('tabindex', -1);
    setTimeout(() => {
        element.focus();
        element.addEventListener('blur', event => {
            event.currentTarget.removeAttribute('tabindex');
        });
    }, 500)
}

export function toggleScroll(condition, lastScroll) {
    if (condition) {
        document.body.classList.add('noScroll');
        document.body.style.top = `-${lastScroll}px`;
        document.body.style.height = `-${lastScroll}px`;
    } else {
        document.body.classList.remove('noScroll');
        document.body.style.top = null;
        document.body.style.height = null;
        window.scrollTo(0, lastScroll);
    }
}

export function stripHtml(html) {
    let stripHtmlResult;

    if (html) {
        stripHtmlResult = html.replace(/<[^>]+>/g, '');
        stripHtmlResult = stripHtmlResult.replace(/&nbsp;/g, ' ');
    } else {
        stripHtmlResult = '';
    }

    return stripHtmlResult;
}

export function isIE() {
    if (typeof window !== 'undefined') {
        let ua = window.navigator.userAgent;
        /* MSIE used to detect old browsers and Trident used to newer ones*/
        let is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

        return is_ie;
    }
    return false;
}

export function isIEorEdge() {
    // Created a separate function in case we want to target Edge as well as old IE
    // isIE function will be used to target non-edge IE

    if (typeof window !== 'undefined') {
        let ua = window.navigator.userAgent;
        let is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1 || ua.indexOf('Edge/') > -1;

        return is_ie;
    }
    return false;
}

export function stringToCamelCase(string = '') {
    const splitStr = string.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        if (splitStr.indexOf(splitStr[i]) !== 0) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
    }

    return splitStr.join('').replace(/[^a-zA-Z]+/g, '');
}

export function stringToCapitalize(string = '') {
    const splitStr = string.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(' ');
}

export function stripSpecialChars(string = '') {
    return string.replace(/[^a-zA-Z ]+/g, '');
}

export function isObjectEmpty(object) {
    for (let key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            return false;
        }
    }
    return true;
}

export const setMonths = (label = '') => {
    let months = [];
    for (let i = 0; i <= 12; i++) {
        if (i === 0) {
            months.push({id: label, value: label});
        } else if (i < 10 && i > 0) {
            months.push({id: i, value: '0' + i.toString()});
        } else {
            months.push({id: i, value: i.toString()});
        }
    }
    return months;
};

export const setYears = (label = '', startYear = 1900) => {
    let years = [{id: label, value: label}];
    const currentYear = new Date().getFullYear();
    for (let i = startYear; i <= currentYear; i++) {
        years.push({id: i.toString(), value: i.toString()})
    }
    return years;
};

export function setCurrentYear() {
    const currentYear = new Date().getFullYear();
    return currentYear;
};

export function objectWithLabel(object) {
    // return new object with value fields.text
    // that usually come from Label contentful component
    // Also remove the object from the list if .fields.text is undefined/null
    // return {key : fields.text}
    let newObject = {};
    for (let property in object) {
        if (object[property]?.fields?.text) {
            newObject[property] = object[property]?.fields?.text;
        }
    }
    return newObject
}

export function addDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
}

export function substractDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() - days)
    return copy
}

export function formatDateToStringValue(strDate, srcFormat, dstFormat) {
    const dateMomentObject = moment(strDate, srcFormat);
    return dateMomentObject.format(dstFormat);
}

export function formatDate(date, joinChar = '-', format = 'yyyy-mm-dd') {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }

    if (day.length < 2) {
        day = '0' + day;
    }
    if (format === 'yyyy-mm-dd') {
        return [year, month, day].join(joinChar);
    } else if (format === 'dd-mm-yyyy') {
        return [day, month, year].join(joinChar);
    }
}

export function getDateInNinetyDays(date = new Date()) {
    return addDays(date, 90);
}

export function splitIntoTwoArrays(array = []) {
    if (array.length < 2) {
        console.error('Please provide an array with at least 2 elements');
        return;
    }
    const halfOfArray = Math.floor(array.length / 2);

    const firstHalf = array.slice(0, halfOfArray);
    const secondHalf = array.slice(halfOfArray);

    return [firstHalf, secondHalf];
}

export function getReviewAndRatingId(productOverview) {
    let reviewAndRatingId;
    if (productOverview) {
        let productVariants = productOverview?.fields?.productVariants;
        if (productVariants) {
            for (let i = 0; i < productVariants.length; i++) {
                let productVariant = productVariants[i];
                if (productVariant.fields.isBazaarVoiceIdentifier) {
                    reviewAndRatingId = productVariant?.fields?.bazaarVoiceId;
                    // if reviewAndRatingId is null use sku/ean
                    if (reviewAndRatingId === undefined) {
                        let sku = productVariant.fields.sku;
                        if (sku) {
                            reviewAndRatingId = sku;
                        } else if (sku === undefined) {
                            let ean = productVariant.fields.ean;
                            reviewAndRatingId = ean;
                        }
                        if (reviewAndRatingId === undefined) {
                            console.error('No reviewAndRatingId specified for product variant Bazaar Voice identifier:',
                                productVariant?.fields?.name);
                        }
                    }
                }
            }
        }
    }
    return reviewAndRatingId;
}



export function loadScript(source, callback) {
    let script = document.createElement('script');
    script.src = source;
    script.onload = () => callback(null, script);
    script.onerror = () => callback(new Error(`Script load error for ${source}`));
    const head = document.getElementsByTagName('head');
    if (head) {
        head[0].appendChild(script);
    }
}

export async function asyncForEach(array, container, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], container);
    }
}

export function createHtmlFriendlyId(text) {
    // This strips all the dashes and replaces spaces to dashes.
    // This makes it easier to read and provides nice and clean ids
    return text.toLowerCase()
        .replace(/ - /g, '-')
        .replace(/ /g, '-');
}


export function checkPattern(input) {
    return input.value.search(new RegExp(input.getAttribute('pattern'))) < 0;
}

export function enableDisableFocusOnElements(container, enable) {
    if (container) {
        const allLinks = container.querySelectorAll('* > a');
        if (allLinks && allLinks.length > 0) {
            for (let item of allLinks) {
                enableTabIndex(item, enable);
            }
        }
        const allButtons = container.querySelectorAll('* > button');
        if (allButtons && allButtons.length > 0) {
            for (let item of allButtons) {
                enableTabIndex(item, enable);
            }
        }
        const allInputs = container.querySelectorAll('* > input');
        if (allInputs && allInputs.length > 0) {
            for (let item of allInputs) {
                enableTabIndex(item, enable);
            }
        }
    }
}

export function getCanonicalURL(path, scheme = MetaConstants.https) {
    const url = process.env.BASE_URL + path;
    return url.includes(scheme) ? url : scheme + url;
}

export function addBVScript() {
    // Render bazaarVoice script after the last render of the layout in the dom
    const bazaarVoiceURL = process.env.BAZAAR_VOICE_URL;

    if(bazaarVoiceURL) {

        const s = document.createElement('script');
        s.async = true;
        s.type = 'text/javascript';
        s.src = process.env.BAZAAR_VOICE_URL.replace(new RegExp('"', 'g'), '');

        var firstScript = document.getElementsByTagName('script')[0]
        if (firstScript) {
            firstScript.parentNode.insertBefore(s, firstScript);
        }

    } else {
        return console.log('Please set a bazaarVoiceURL');
    }
}

export function addBVCollectionPageCallback() {
    window.setTimeout(function() {
        const inlineRating = document.querySelectorAll("[data-bv-show='inline_rating']");
        if (inlineRating) {
            for (let i = 0; i < inlineRating.length; i++) {
                const inlineRatingElement = inlineRating[i]
                inlineRatingElement.classList.remove('no-reviews');
                if (inlineRatingElement.innerText.includes('(0)')) {
                    inlineRatingElement.classList.add('no-reviews');
                }
            }
        }
    }, 2000);
}

export function addBVProductPageCallback() {
    window.bvCallback = function (BV) {
        function addAnalyticClassToBazaarVoiceElement(elementClass, analyticHookClass, dataActionDetail, parentElementClass, attributeToReadForValue, removeFromValue) {
            if (typeof (document) != 'undefined') {
                let rootElement = document;
                if (parentElementClass) {
                    let parent = document.getElementsByClassName(parentElementClass);
                    if (parent && parent.length > 0 && typeof (parent[0]) != 'undefined') {
                        rootElement = parent[0];
                    }
                }
                const elements = rootElement.getElementsByClassName(elementClass);
                if (elements && elements.length > 0) {
                    //Add some GA class to track bv event
                    for (let i = 0; i < elements.length; i++) {
                        let valueActionDetail = dataActionDetail;
                        if (typeof (elements[i]) != 'undefined' && elements[i] != null) {
                            elements[i].classList.add(analyticHookClass);

                            if (attributeToReadForValue) {
                                let value = '';
                                if (elements[i].getAttribute(attributeToReadForValue)) {
                                    value = elements[i].getAttribute(attributeToReadForValue);
                                    if (removeFromValue) {
                                        value = value.replace(removeFromValue, '');
                                    }
                                }
                                valueActionDetail = valueActionDetail.replace('%value%', value);
                            }

                            elements[i].setAttribute('data-action-detail',
                                valueActionDetail);
                        }
                    }
                }
            }
        }

        if (window?.bvDCC) {
            for (var i = 0, len = window.bvDCC.catalogData.catalogProducts.length; i < len; ++i) {
                BV.pixel.trackEvent('CatalogUpdate', {
                    type: 'Product',
                    locale: window.bvDCC.catalogData.locale,
                    catalogProducts: [window.bvDCC.catalogData.catalogProducts[i]]
                });
            }
        }

        BV.reviews.ready().then(() => {
            window.setTimeout(function () {
                addAnalyticClassToBazaarVoiceElement('bv_button_buttonFull',
                    'event_button_click',
                    'Open Write Review Panel',
                    'bv_main_container_CTA_row');

                addAnalyticClassToBazaarVoiceElement('bv-focusable',
                    'event_button_click',
                    'Open Dropdown Menu',
                    'bv-control-bar-sort');

                addAnalyticClassToBazaarVoiceElement('bv-dropdown-target',
                    'event_button_click',
                    'Open Dropdown Menu',
                    'bv-control-bar-sort');

                addAnalyticClassToBazaarVoiceElement('bv-expand-filter-button',
                    'event_button_click',
                    'Open Dropdown Menu');

                addAnalyticClassToBazaarVoiceElement('bv-content-btn-feedback-yes',
                    'event_button_click',
                    'Helpful Yes');

                addAnalyticClassToBazaarVoiceElement('bv-content-btn-feedback-no',
                    'event_button_click',
                    'Helpful No');

                addAnalyticClassToBazaarVoiceElement('bv_stars_button_container',
                    'event_internal_link',
                    'See All Reviews Panel');

                addAnalyticClassToBazaarVoiceElement('bv_avgRating',
                    'event_internal_link',
                    'See All Reviews Panel');

                addAnalyticClassToBazaarVoiceElement('bv_numReviews_text',
                    'event_internal_link',
                    'See All Reviews Panel');

                addAnalyticClassToBazaarVoiceElement('bv-content-btn-pages-load-more',
                    'event_button_click',
                    'See More Reviews');

                addAnalyticClassToBazaarVoiceElement('bv-dropdown-item',
                    'event_button_click',
                    'Filter %value%',
                    'bv-dropdown-active',
                    'data-bv-dropdown-value');

                addAnalyticClassToBazaarVoiceElement('bv-rating-link',
                    'event_button_click',
                    'Star Rating %value%',
                    'bv-submission-star-rating-control',
                    'id',
                    'bv-radio-rating-');

                addAnalyticClassToBazaarVoiceElement('bv-text',
                    'event_button_click',
                    'Form Field %value%',
                    'bv-fieldset-usernickname',
                    'name');

                addAnalyticClassToBazaarVoiceElement('bv-select',
                    'event_button_click',
                    'Form Field %value%',
                    'bv-fieldset-contextdatavalue_AgeMonth',
                    'name');

                addAnalyticClassToBazaarVoiceElement('bv-select',
                    'event_button_click',
                    'Form Field %value%',
                    'bv-fieldset-contextdatavalue_AgeYear',
                    'name');

                addAnalyticClassToBazaarVoiceElement('bv-text',
                    'event_button_click',
                    'Form Field %value%',
                    'bv-email-field',
                    'name');

                addAnalyticClassToBazaarVoiceElement('bv-text',
                    'event_button_click',
                    'Form Field %value%',
                    'bv-fieldset-userlocation',
                    'name');

                addAnalyticClassToBazaarVoiceElement('bv-text',
                    'event_button_click',
                    'Form Field %value%',
                    'bv-fieldset-title',
                    'name');

                addAnalyticClassToBazaarVoiceElement('bv-text',
                    'event_button_click',
                    'Form Field %value%',
                    'bv-fieldset-reviewtext',
                    'name');

                addAnalyticClassToBazaarVoiceElement('bv-form-actions-submit',
                    'event_button_click',
                    'Submit');

                // Add a class on user block if 0 Reviews from BV.
                const reviewText = document.getElementsByClassName('bv_numReviews_component_container');
                if (reviewText.length > 0 && reviewText[0] !== null) {
                    if (reviewText[0].innerText.includes('(0)')) {

                        const userBlock = document.getElementsByClassName('ob-customer-review');
                        if (userBlock[0] !== null && typeof userBlock[0] != 'undefined') {
                            userBlock[0].classList.add('no-reviews');
                        }
                    }
                }


                const inlineRating = document.querySelector("[data-bv-show='inline_rating']");
                if (inlineRating !== null && inlineRating.innerText.includes('(0)')) {
                    // Find inline-rating (0)
                    inlineRating.classList.add('no-reviews');
                }

            }, 2000);
        });
    }
}


function enableTabIndex(item, enable) {
    if (!enable) {
        if (item.getAttribute('tabindex') && item.getAttribute('tabindex') >= 0) {
            item.setAttribute('data-previous-tabIndex', item.getAttribute('tabindex'));
        }
        item.setAttribute('tabindex', -1);
    } else {
        let newTabIndex = null;
        if (item.getAttribute('data-previous-tabIndex')) {
            newTabIndex = item.getAttribute('data-previous-tabIndex');
            item.setAttribute('tabindex', newTabIndex);
        } else {
            item.removeAttribute('tabindex');
        }
    }
}

export function addProductListforSearchContent (productList){
    let stringProductList = '';
    if(Array.isArray(productList) && productList.length > 0){
         stringProductList =  customJsonstringify(productList)
    }
    return stringProductList
}

export function removeHtmlTag(query) {
    query = query.replace(/[&\/\\#$~%.'":*<>[\]{}]/g, '');
    return query;
}

export const customJsonstringify = str => JSON.stringify(str).replace(/([&"'<>])/g, ( char) => {
    switch (char) {
    case '&':
        return '\u0026'
    case '<':
        return '\u003c'
    case '>': 
        return '\u003e'
    case '"':
        return '\u0022'
    case "'":
        return '\u0027'
    default:
        return char
    }
  })

  export function handleGAEvents (gaEventCategory, gaEventAction, gaEventLabel) {
    window && window.dataLayer && window.dataLayer.push({
        event: 'customEvent',
        GAeventCategory: `${gaEventCategory}`,
        GAeventAction: `${gaEventAction}`,
        GAeventLabel: `${gaEventLabel}`
    });
};


export const addRemainder = (calenderOption, selectedTypeOption) => {
    let dt = new Date();
    let no_of_months = calenderOption?.noOfMonths;
    let eventTitle = calenderOption?.title;
    let eventNote = calenderOption?.note;
    dt.setMonth(dt.getMonth() + no_of_months);
    let endDate = dt
      .toISOString()
      .replaceAll(":", "")
      .replaceAll("-", "")
      .replaceAll(".", "");
    let date = dt
      .toISOString()
      .replaceAll(":", "")
      .replaceAll("-", "")
      .split(".");
    let outlookDate = dt.toISOString().split(".");
    let url;
    let icsData;

    switch (selectedTypeOption.type) {
      case StartGuideBlockConstants.Google:
        url =
          selectedTypeOption?.url +
          eventTitle +
          "&dates=" +
          endDate +
          "/" +
          endDate +
          "&details=" +
          eventNote;
        window.open(url);
      case StartGuideBlockConstants.Yahoo:
        url =
          selectedTypeOption?.url +
          eventTitle +
          "&st=" +
          endDate +
          "&et=" +
          endDate +
          "&desc=" +
          eventNote;
        window.open(url);
      case StartGuideBlockConstants.Outlook:
        url =
          selectedTypeOption?.url +
          "&startdt=" +
          outlookDate[0] +
          "Z" +
          "&enddt=" +
          outlookDate[0] +
          "Z" +
          "&subject=" +
          eventTitle +
          "&body=" +
          eventNote;
        window.open(url);
      case StartGuideBlockConstants.Other:
        icsData = "BEGIN:VCALENDAR\nVERSION:2.0\r\n";
        icsData += "BEGIN:VEVENT\r\n";
        icsData += "DTSTART:" + date[0] + "Z" + "\r\n";
        icsData += "DTEND:" + date[0] + "Z" + "\r\n";
        icsData += `SUMMARY:${eventTitle}\r\n`;
        icsData += `DESCRIPTION:${eventNote}\r\n`;
        icsData += "LOCATION:\r\n";
        icsData += "BEGIN:VALARM\r\n";
        icsData += "TRIGGER:-PT15M\r\n";
        icsData += "ACTION:DISPLAY\r\n";
        icsData += "DESCRIPTION:REMINDER\r\n";
        icsData += "END:VALARM\r\nEND:VEVENT\r\nEND:VCALENDAR";
        window.open(
          "data:text/calendar;charset=utf8," + encodeURIComponent(icsData)
        );
    }
  };